// import { useApiClient } from "./useApiClient";
import { type UserBecomeSellerInterface } from "~/models/seller";

export const useSellerApi = () => {
  const { $axios } = useNuxtApp();

  const getSellerTypes = async () => {
    return $axios.get(`/api/companies/types`);
  };

  const becomeSeller = async (id: number, data: UserBecomeSellerInterface) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("tin", data.tin);
    formData.append("type", data.type || "");
    formData.append("locality_id", data.locality_id);
    formData.append("email", data.email);

    for (let i = 0; i < data.phone.length; i++) {
      formData.append(`phones[${i}][number]`, data.phone[i]);
      formData.append(`phones[${i}][viber]`, data.viber[i] || 0);
      formData.append(`phones[${i}][telegram]`, data.telegram[i] || 0);
      formData.append(`phones[${i}][whatsapp]`, data.whatsapp[i] || 0);
    }

    for (let i = 0; i < data.code.length; i++) {
      formData.append(`phones[${i}][code]`, data.code[i]);
    }

    for (let i = 0; i < data.files.length; i++) {
      formData.append("files[]", data.files[i]);
    }

    try {
      return await $axios.post(`/api/users/${id}/become-seller`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const getAllSellers = async () => {
    return $axios.get(`/api/sellers`);
  };

  const getSellerById = async (id: number) => {
    return $axios.get(`/api/sellers/${id}`);
  };

  const createCustomDelivery = async (sellerId: number, data: any) => {
    return $axios.post(`/api/sellers/${sellerId}/custom-deliveries`, data);
  };

  const updateCustomDelivery = async (
    sellerId: number,
    id: number,
    data: any
  ) => {
    return $axios.put(`/api/sellers/${sellerId}/custom-deliveries/${id}`, data);
  };

  const deleteCustomDelivery = async (sellerId: number, id: number) => {
    return $axios.delete(`/api/sellers/${sellerId}/custom-deliveries/${id}`);
  };

  const getCustomDelivery = async (sellerId: number, deliveryId: number) => {
    return $axios.get(
      `/api/sellers/${sellerId}/custom-deliveries/${deliveryId}`
    );
  };

  const getCustomDeliveries = async (id: number) => {
    return $axios.get(`/api/sellers/${id}/custom-deliveries`);
  };

  const createDelivery = async (sellerId: number, data: any) => {
    return $axios.post(`/api/sellers/${sellerId}/deliveries`, data);
  };

  const updateDelivery = async (sellerId: number, id: number, data: any) => {
    return $axios.put(`/api/sellers/${sellerId}/deliveries/${id}`, data);
  };

  const getDelivery = async (sellerId: number, deliveryId: number) => {
    return $axios.get(`/api/sellers/${sellerId}/deliveries/${deliveryId}`);
  };

  const getDeliveries = async (id: number) => {
    return $axios.get(`/api/sellers/${id}/deliveries`);
  };

  const getDeliveryTypes = async () => {
    return $axios.get(`/api/sellers/${id}/delivery-types`);
  };

  const getPaymentMethods = async (id: number) => {
    return $axios.get(`/api/sellers/${id}/payment-methods`);
  };

  const getPayoutMethod = async (sellerId: number, paymentMethodId: number) => {
    return $axios.get(
      `/api/sellers/${sellerId}/payout-methods/${paymentMethodId}`
    );
  };

  const savePaymentMethods = async (id: number, data: any) => {
    return $axios.post(`/api/sellers/${id}/payment-methods/attach`, data);
  };

  const savePaymentMethod = async (id: number, data: any) => {
    return $axios.post(`/api/sellers/${id}/payment-methods/attach`, data);
  };

  const getCustomPaymentMethods = async (id: number) => {
    return $axios.get(`/api/sellers/${id}/custom-payment-methods`);
  };

  const saveCustomPaymentMethods = async (id: number, data: any) => {
    return $axios.post(`/api/sellers/${id}/custom-payment-methods`, data);
  };

  const getCustomPaymentMethod = async (
    sellerId: number,
    paymentMethodId: number
  ) => {
    return $axios.get(
      `/api/sellers/${sellerId}/custom-payment-methods/${paymentMethodId}`
    );
  };

  const createCustomPaymentMethod = async (sellerId: number, data: any) => {
    return $axios.post(`/api/sellers/${sellerId}/custom-payment-methods`, data);
  };

  const updateCustomPaymentMethod = async (
    sellerId: number,
    paymentMethodId: number,
    data: any
  ) => {
    return $axios.put(
      `/api/sellers/${sellerId}/custom-payment-methods/${paymentMethodId}`,
      data
    );
  };

  const deleteCustomPaymentMethod = async (
    sellerId: number,
    paymentMethodId: number
  ) => {
    return $axios.delete(
      `/api/sellers/${sellerId}/custom-payment-methods/${paymentMethodId}`
    );
  };

  return {
    becomeSeller,
    getSellerTypes,
    getAllSellers,
    getSellerById,
    createDelivery,
    updateDelivery,
    createCustomDelivery,
    updateCustomDelivery,
    deleteCustomDelivery,
    getCustomDelivery,
    getCustomDeliveries,
    getDelivery,
    getDeliveries,
    getDeliveryTypes,
    getPaymentMethods,
    getPayoutMethod,
    savePaymentMethods,
    savePaymentMethod,
    getCustomPaymentMethods,
    getCustomPaymentMethod,
    createCustomPaymentMethod,
    updateCustomPaymentMethod,
    deleteCustomPaymentMethod,
    saveCustomPaymentMethods,
  };
};
